import { mapGetters } from "vuex";
import { omit } from "lodash";
import { addCurrentTranslation } from "~/utils";
import {
  BUILDER_EVENTS,
  BUILDER_STOREFRONT_EVENTS,
} from "~/builder/builderConfig";
import { sendMessageToParent, smoothScrollTo } from "~/builder/builderUtils";

export default {
  props: {
    components: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tempComponent: null,
      selectedIdx: -1,
      hoveredIdx: -1,
      hoveredEl: null,
      selectedEl: null,
    };
  },
  watch: {
    hoveredEl(val, oldValue) {
      if (oldValue) {
        oldValue.removeEventListener("click", this.handleClick);
      }
      if (val) {
        val.addEventListener("click", this.handleClick);
      }
    },
    selectedEl(val, oldValue) {
      if (oldValue) {
        delete oldValue.dataset.editorBlockSelected;
      }
      if (val) {
        val.dataset.editorBlockSelected = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      getCurrentLangId: "general/getCurrentLangId",
      getDefaultLangId: "general/getDefaultLangId",
      isEditMode: "general/getIsEditMode",
    }),
    blocks() {
      return [];
    },
  },
  mounted() {
    if (this.isEditMode) {
      this.tempComponent = this.components || [];
      window.addEventListener("message", this.handleMessage);
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    handleMouseEnter(idx) {
      if (!this.isEditMode) return;
      this.hoveredIdx = idx;
      this.hoveredEl = document.querySelector(
        `[data-editor-block-index="${this.hoveredIdx}"]`
      );
      sendMessageToParent({
        event: BUILDER_STOREFRONT_EVENTS.HOVER_BLOCK,
        data: {
          id: this.blocks[this.hoveredIdx].id,
          index: this.hoveredIdx,
        },
      });
    },
    handleMouseLeave(idx) {
      if (!this.isEditMode) return;
      this.hoveredIdx = -1;
      this.hoveredEl = null;
    },
    handleClick() {
      if (!this.isEditMode) return;
      this.selectedIdx = this.hoveredIdx;
      this.setSelectedElement();
      sendMessageToParent({
        event: BUILDER_STOREFRONT_EVENTS.SELECT_BLOCK,
        data: {
          id: this.blocks[this.selectedIdx].id,
          index: this.selectedIdx,
        },
      });
    },
    async setSelectedElement(scrollToEl = false) {
      if (!this.isEditMode) return;
      const selector = `[data-editor-block-index="${this.selectedIdx}"]`;
      if (scrollToEl) {
        this.selectedEl = await this.scrollToEl(selector);
      } else {
        this.selectedEl = document.querySelector(selector);
      }
    },

    async scrollToEl(selector) {
      let el = document.querySelector(selector);

      if (el) {
        await smoothScrollTo(el);
        el = document.querySelector(selector);
        await smoothScrollTo(el);
      }
      return el;
    },

    handleMessage(e) {
      if (!this.isEditMode) return;
      const data = JSON.parse(e.data);

      const dataWithTranslation = addCurrentTranslation(
        data.data,
        this.getCurrentLangId(this.$i18n.locale),
        this.getDefaultLangId
      );

      switch (data.event) {
        case BUILDER_EVENTS.SELECT_BLOCK:
          this.handleSelectBlock(dataWithTranslation);
          break;

        case BUILDER_EVENTS.UPDATE_BLOCK_CONTENT:
          this.handleUpdateBlockContent(dataWithTranslation);
          break;

        case BUILDER_EVENTS.SORT_BLOCKS:
          this.handleSortBlocks(dataWithTranslation);
          break;

        case BUILDER_EVENTS.REMOVE_BLOCK:
          this.handleRemoveBlock(dataWithTranslation);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          break;
        case BUILDER_EVENTS.CHANGE_PAGE:
          this.handleChangePage(dataWithTranslation);
          break;
      }
    },

    handleSelectBlock(data) {
      this.selectedIdx = data.block_position;
      this.mergeBlockContent(this.selectedIdx, data.block);
      this.setSelectedElement(true);
    },

    handleUpdateBlockContent(data) {
      this.selectedIdx = data.block_position;
      this.mergeBlockContent(this.selectedIdx, data.block);
      this.setSelectedElement();
    },

    handleRemoveBlock(data) {
      this.selectedIdx = data.block_position;
      this.tempComponent = this.tempComponent.filter(
        (_, idx) => data.remove_idx !== idx
      );
      this.selectedEl = null;
    },
    handleChangePage() {
      this.tempComponent = this.components || [];
      this.selectedEl = null;
      this.selectedIdx = -1;
      this.hoveredIdx = -1;
    },
    moveElement(arr, oldIndex, newIndex) {
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr;
    },
    handleSortBlocks(data) {
      this.moveElement(this.tempComponent || [], data.oldIndex, data.newIndex);
      if (
        data.selected_block_position >= 0 &&
        data.block_position === data.selected_block_position
      ) {
        this.selectedIdx = data.selected_block_position;
        this.setSelectedElement(true);
      } else {
        this.scrollToEl(`[data-editor-block-index="${data.block_position}"]`);
      }
    },
    mergeBlockContent(index, block) {
      Object.assign(
        this.tempComponent[index],
        omit(block, "block_type_content")
      );
    },
  },
};
