const defaultWindow = typeof window === "undefined" ? undefined : window;

const noop = () => {};

export function useEventListener(...args) {
  let target;
  let events;
  let listeners;
  let options;

  if (typeof args[0] === "string" || Array.isArray(args[0])) {
    [events, listeners, options] = args;
    target = defaultWindow;
  } else {
    [target, events, listeners, options] = args;
  }

  if (!target) return noop;

  if (!Array.isArray(events)) events = [events];
  if (!Array.isArray(listeners)) listeners = [listeners];

  const register = (el, event, listener, options) => {
    el.addEventListener(event, listener, options);
    return () => el.removeEventListener(event, listener, options);
  };

  const cleanups = [];
  const cleanup = () => {
    cleanups.forEach((fn) => fn());
    cleanups.length = 0;
  };

  cleanups.push(
    ...events.flatMap((event) => {
      return listeners.map((listener) =>
        register(target, event, listener, options)
      );
    })
  );

  const stop = () => {
    cleanup();
  };

  return stop;
}

export function useElementBounding(target, cb = () => {}, options = {}) {
  const {
    reset = true,
    windowResize = true,
    windowScroll = true,
    immediate = true,
  } = options;

  let height = 0;
  let bottom = 0;
  let left = 0;
  let right = 0;
  let top = 0;
  let width = 0;
  let x = 0;
  let y = 0;

  function update() {
    const el = target;

    if (!el) {
      if (reset) {
        height = 0;
        bottom = 0;
        left = 0;
        right = 0;
        top = 0;
        width = 0;
        x = 0;
        y = 0;
      }
      // eslint-disable-next-line node/no-callback-literal
      cb({ height, bottom, left, right, top, width, x, y });
      return;
    }

    const rect = el.getBoundingClientRect();

    height = rect.height;
    bottom = rect.bottom;
    left = rect.left;
    right = rect.right;
    top = rect.top;
    width = rect.width;
    x = rect.x;
    y = rect.y;
    // eslint-disable-next-line node/no-callback-literal
    cb({ height, bottom, left, right, top, width, x, y });
  }

  if (windowScroll)
    useEventListener("scroll", update, { capture: true, passive: true });
  if (windowResize) useEventListener("resize", update, { passive: true });

  if (immediate) update();

  return {
    height,
    bottom,
    left,
    right,
    top,
    width,
    x,
    y,
    update,
  };
}

export function sendMessageToParent(message) {
  if (typeof message !== "string") {
    message = JSON.stringify(message);
  }
  window.parent.postMessage(message, "*");
}

export function smoothScrollTo(elem, behavior = "smooth") {
  const rect = elem.getBoundingClientRect();
  const targetPosition = Math.floor(
    rect.top + window.scrollY - window.innerHeight / 2 + rect.height / 2
  );
  const maxPositionValue = Math.max(0, targetPosition);
  window.scrollTo({
    top: maxPositionValue,
    behavior,
  });

  return new Promise((resolve, reject) => {
    const failed = setTimeout(() => {
      window.removeEventListener("scroll", scrollHandler);
      resolve();
    }, 2000);

    const scrollHandler = () => {
      if (Math.floor(window.scrollY) === maxPositionValue) {
        window.removeEventListener("scroll", scrollHandler);
        clearTimeout(failed);
        resolve();
      }
    };
    if (Math.floor(window.scrollY) === maxPositionValue) {
      clearTimeout(failed);
      resolve();
    } else {
      window.addEventListener("scroll", scrollHandler);
    }
  });
}
