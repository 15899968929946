
import LazyHydrate from "vue-lazy-hydration";
import { mapGetters } from "vuex";
import builderMixin from "~/builder/builderMixin";
import useEvents from "~/mixins/useEvents";

export default {
  name: "BlocksContainer",
  components: {
    PageBuilder: () => import("~/components/editor/PageBuilder.vue"),
    LazyHydrate,
  },
  mixins: [builderMixin, useEvents],
  props: {
    components: {
      type: Array,
      required: true,
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    showCollectionFilter: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      getCurrentLangId: "general/getCurrentLangId",
      getDefaultLangId: "general/getDefaultLangId",
      isEditMode: "general/getIsEditMode",
    }),
    blocks() {
      if (!this.isEditMode) return this.components;

      const currentBlocks = this.tempComponent || this.components;

      return currentBlocks.map((block, idx) => {
        return {
          ...block,
          props: {
            "data-editor-block": JSON.stringify({
              index: idx,
              id: block.id,
            }),
            "data-editor-block-index": idx,
          },
        };
      });
    },
  },
  mounted() {
    this.observerOnMounted();
  },
  beforeDestroy() {
    this.observerOnBeforeUnmount();
  },
};
